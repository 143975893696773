export async function fetchRetry(n, input, init) {
    try {
        return await fetch(input, init);
    }
    catch (err) {
        if (n === 1) {
            throw err;
        }
        return await fetchRetry(n - 1, input, init);
    }
}
