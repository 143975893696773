export function controllerMethod(controller, methodName) {
    // @ts-ignore
    const method = controller[methodName];
    if (typeof method == "function") {
        return method;
    }
    else if (method != undefined) {
        return () => method;
    }
    else {
        return () => void 0;
    }
}
