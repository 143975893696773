export async function scrollToElement(element, { behavior = "smooth", block = "start", inline = "nearest" } = {}) {
    element.scrollIntoView({ behavior, block, inline });
}
export async function scrollAbsoluteTop(element, { behavior = "smooth" } = {}) {
    element.scrollTo({ top: 0, left: 0, behavior });
}
export async function scrollAbsoluteBottom(element, { behavior = "smooth" } = {}) {
    if (element == window) {
        element.scrollTo({ top: document.body.scrollHeight, left: 0, behavior });
    }
    else {
        element.scrollTo({ top: element.scrollHeight, left: 0, behavior });
    }
}
export async function scrollAbsoluteLeft(element, { behavior = "smooth" } = {}) {
    element.scrollTo({ left: 0, behavior });
}
export async function scrollAbsoluteRight(element, { behavior = "smooth" } = {}) {
    if (element == window) {
        element.scrollTo({ left: document.body.scrollWidth, behavior });
    }
    else {
        element.scrollTo({ left: element.scrollWidth, behavior });
    }
}
export async function scrollUp(element, amount, { behavior = "smooth" } = {}) {
    element.scrollBy({ top: -amount, left: 0, behavior });
}
export async function scrollDown(element, amount, { behavior = "smooth" } = {}) {
    element.scrollBy({ top: amount, left: 0, behavior });
}
export async function scrollLeft(element, amount, { behavior = "smooth" } = {}) {
    element.scrollBy({ top: 0, left: -amount, behavior });
}
export async function scrollRight(element, amount, { behavior = "smooth" } = {}) {
    element.scrollBy({ top: 0, left: amount, behavior });
}
export function getScrollParent(node) {
    if (!node) {
        return null;
    }
    if (node == document.body) {
        return window;
    }
    const overflowY = getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== "visible" && overflowY !== "hidden";
    if (isScrollable && node.scrollHeight >= node.clientHeight) {
        return node;
    }
    return getScrollParent(node.parentElement) || document.body;
}
